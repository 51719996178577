

.send_otp {
    text-align: right;
}
.send_otp_link {
    font-size: 14px;
    font-weight: 500;
    color: #47C475;
    font-weight: bold;
}

.Verify_otp {
    font-size: 14px;
    font-weight: 500;
    color: #212529;
}
.form-otp {
    width: 20% !important;
    text-align: center;
}

.otp_section div {
    justify-content: space-between;
}
.otp_section div span {
    visibility: hidden;
}
.btn-pm-custom {
    width: 100%;
    margin-top: 15px;
    font-weight: bold;
    --bs-btn-bg: #28292b;
    --bs-btn-border-color: #1a1b1b;
    --bs-btn-hover-bg: #1a1b1b;
    --bs-btn-hover-border-color: #1a1b1b;
}
.resend_otp {
    text-align: right;
    margin-top: 8px;
}
.react-custom-flag-select__select__selector .react-custom-flag-select__select__dropdown-name div:nth-child(1)::before {
    content: "+";
}
.user-avatar img.profile {
    height: 70px;
    width: 70px;
}
.multiple-question.multiple-question-image img {
    height: 220px;
    width: 100%;
}